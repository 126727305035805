import axios from 'axios';

const apiManager = axios.create({
  baseURL: "https://api.lookpin.co.kr", // 기본 서버 주소 입력
  headers: {
    "Lookpin-Platform": "Web-Admin"
  }
});

apiManager.interceptors.request.use(
  config => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      config.headers['Authorization'] = 'Token ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

apiManager.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    if (status === 401 && config.url != "/api/v3/admins/auth/sign_in") {
        const originalRequest = config;
        const refreshToken = await localStorage.getItem("refreshToken");

        const {data}  = await axios.post(
          'https://api.lookpin.co.kr/api/v3/admins/auth/refresh',
          {},
          { headers: { authorization: refreshToken, "Lookpin-Platform": "Web-Admin" } }
        );
        const payload = data.data.payload
        await localStorage.setItem("accessToken", payload.access_token)
        await localStorage.setItem("refreshToken", payload.refresh_token)
        await localStorage.setItem("accessTokenExpiredAt", payload.access_token_expired_at);
        await localStorage.setItem("refreshTokenExpiredAt", payload.refresh_token_expired_at);
        originalRequest.headers.authorization = `Token ${payload.access_token}`;
        return axios(originalRequest);
      }
    return Promise.reject(error);
  }
);


export default apiManager