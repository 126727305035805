import React, { useState } from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, message, Form, Input } from 'antd';
import { postLogin } from '../lib/adminAPI'
import dayjs from 'dayjs';
import { Navigate, useNavigate, navigate } from "react-router";

const LoginPage = () => {
  const [loginMessage, setLoginMessage] = useState([]);
  const accessTokenExpiredAt = localStorage.getItem("accessTokenExpiredAt")
  const navigate = useNavigate();
  if(accessTokenExpiredAt && dayjs().diff(accessTokenExpiredAt, 's') < 0) {
    return <Navigate to={"/transaction-amount"} />
  }
  const onFinish = (values) => {
    postLogin(values["loginId"], values["password"]).then((res) => {
      const payload = res.data.data.payload
      if(payload) {
        setLoginMessage(undefined)
        const id = payload.id

        if (id == 18 || id == 45 || id == 51) {
          const accessToken = payload.access_token
          const accessTokenExpiredAt = payload.access_token_expired_at
          const refreshToken = payload.refresh_token
          const refreshTokenExpiredAt = payload.refresh_token_expired_at

          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("accessTokenExpiredAt", accessTokenExpiredAt);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("refreshTokenExpiredAt", refreshTokenExpiredAt);
          navigate("/transaction-amount")
        } else {
          localStorage.removeItem("accessToken")
          localStorage.removeItem("accessTokenExpiredAt")
          localStorage.removeItem("refreshToken")
          localStorage.removeItem("refreshTokenExpiredAt")
        }
      }

    }).catch(ex => {
        console.log(ex)
        setLoginMessage("아이디 또는 비밀번호를 틀렸습니다.")
        message.error("아이디 또는 비밀번호를 틀렸습니다.")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("accessTokenExpiredAt")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("refreshTokenExpiredAt")
    });
  };

  const formItemLayout = {
    // labelCol: {
    //   span : 4
    // },
    // wrapperCol: {
    //     span : 500
    // }
  };


  // const buttonItemLayout = { wrapperCol: { span: 14 } }
  const buttonItemLayout = {
    //  wrapperCol: {   xs: { span: 14, offset:4 }, sm: { span: 14, offset:4 },} 
    }

  
  return (
    <div style={{display: "flex", "justify-content": "center", "align-items": "center", height:"100vh"}}>
    <Form
      {...formItemLayout}
      name="normal_login"
      className="login-form"
      size='large'
      initialValues={{
        remember: true,
      }}
      style={{marginBottom:"100px", maxWidth:"500px"}}
      onFinish={onFinish}
    >
      <h1 style={{marginRight:"50px"}}>룩핀 어드민 로그인</h1>
      { loginMessage ? <h3 style={{marginRight:"50px", color:"red"}}>{loginMessage}</h3> : undefined }
      <Form.Item
        name="loginId"
        // label="이메일"
        rules={[
          {
            required: true,
            message: '이메일을 입력해주세요.',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="이메일을 입력해주세요." />
      </Form.Item>
      <Form.Item
        name="password"
        // label="비밀번호"
        rules={[
          {
            required: true,
            message: '비밀번호를 입력해주세요.',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="비밀번호를 입력해주세요."
        />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button type="primary" htmlType="submit" className="login-form-button" style={{width:"100%"}}>
          로그인
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
};
export default LoginPage;