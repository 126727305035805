import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './login/login';
import TransactionAmountPage from './transactionAmount/TransactionAmount';

const App = () => {
	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<LoginPage />}></Route>
          <Route path="/transaction-amount/*" element={<TransactionAmountPage />}></Route>
          {/* <Route path="/transaction-amount/days" element={<TransactionAmountPage />}></Route>
          <Route path="/transaction-amount/months" element={<TransactionAmountPage />}></Route> */}
					<Route path="/login/*" element={<LoginPage />}></Route>
					<Route path="*" element={<LoginPage />}></Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
};

export default App;