import React, { useEffect, useState } from "react";
import { Table,ConfigProvider, Flex, Button } from "antd";
import { Navigate, useNavigate } from "react-router";
import dayjs from "dayjs";
import { getTransactionAmount } from "../lib/adminAPI";

const TransactionAmountPage = () => {
  const [nowData, setNowData] = useState([]);
  const [daysData, setDaysData] = useState([]);
  const [monthsData, setMonthsData] = useState([]);
  const navigate = useNavigate();
  if(!localStorage.getItem("accessToken") || !localStorage.getItem("refreshToken")) {
    return <Navigate to={"/login"} />
  }
  const columns = [
    {
      title: '일자',
      dataIndex: 'date',
      render: (date) => <b>{date}</b>,
      width: 100,
    },
    {
      title: '주문건수',
      dataIndex: 'order_total_count',
      render: (order_total_count) => new Intl.NumberFormat("ko-KR").format(order_total_count)+"건",
      width: 100,
    },
    {
      title: '거래액',
      dataIndex: 'order_total_price',
      render: (order_total_price) => new Intl.NumberFormat("ko-KR").format(order_total_price)+"원",
      width: 100,
    },
  ];

  const daysColumns = [
    {
      title: '일자',
      dataIndex: 'date',
      render: (date) => <b>{date}</b>,
      width: 100,
    },
    {
      title: '주문건수',
      dataIndex: 'order_total_count',
      render: (order_total_count) => new Intl.NumberFormat("ko-KR").format(order_total_count)+"건",
      width: 100,
    },
    {
      title: '거래액',
      dataIndex: 'order_total_price',
      render: (order_total_price) => new Intl.NumberFormat("ko-KR").format(order_total_price)+"원",
      width: 100,
    },
  ];

  const monthsColumns = [
    {
      title: '월별',
      dataIndex: 'date',
      render: (date) => <b>{dayjs(date).format("YYYY년 MM월")}</b>,
      width: 100,
    },
    {
      title: '주문건수',
      dataIndex: 'order_total_count',
      render: (order_total_count) => new Intl.NumberFormat("ko-KR").format(order_total_count)+"건",
      width: 100,
    },
    {
      title: '거래액',
      dataIndex: 'order_total_price',
      render: (order_total_price) => new Intl.NumberFormat("ko-KR").format(order_total_price)+"원",
      width: 100,
    },
  ];
  const appendData = () => {
    console.log(dayjs().startOf('day').format())
    console.log(dayjs().endOf('day').format())
    getTransactionAmount(dayjs().startOf('day').format(), dayjs().endOf('day').format())
      .then((res) => {
        const payload = res.data.data.payload;
        setNowData(payload.days);
      })
      .catch((ex) => {
        console.log(ex);
        localStorage.removeItem("accessToken")
        localStorage.removeItem("accessTokenExpiredAt")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("refreshTokenExpiredAt")
        navigate("/login")
      });

      getTransactionAmount(dayjs().subtract(40,'day').startOf('day').format(), dayjs().subtract(1, 'day').endOf('day').format())
      .then((res) => {
        const payload = res.data.data.payload;
        setDaysData(payload.days.reverse());
      })
      .catch((ex) => {
        console.log(ex);
        localStorage.removeItem("accessToken")
        localStorage.removeItem("accessTokenExpiredAt")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("refreshTokenExpiredAt")
        navigate("/login")
      });

      getTransactionAmount(dayjs().subtract(14,'month').startOf('day').format(), dayjs().endOf('day').format())
      .then((res) => {
        const payload = res.data.data.payload;
        setMonthsData(payload.months.reverse());
      })
      .catch((ex) => {
        console.log(ex);
        localStorage.removeItem("accessToken")
        localStorage.removeItem("accessTokenExpiredAt")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("refreshTokenExpiredAt")
        navigate("/login")
      });
  };

  useEffect(() => {
    appendData();
  }, []);

  return (
    <ConfigProvider
  theme={{
    components: {
      Table: {
        cellFontSize: 12,
        cellFontSizeSM: 12,
        cellPaddingBlockSM: 6
      },
    },
  }}
>
    <div style={{padding: '0 10px'}}>
       <Flex vertical={'vertical'} justify={'center'} align={'center'} style={{marginBottom: "30px"}}>
          <h2>룩핀 거래액 현황</h2>
          <h3>측정 시간 : {dayjs().format("YYYY년 MM월 DD일 HH시 MM분 ss초")}</h3>
          <Button 
          size={"large"}
          type="primary"
          onClick={() => {
            setNowData([])
            setDaysData([])
            setMonthsData([])
            appendData()
            }}>새로고침</Button>
      </Flex>
      <Table
      align="center"
      size="small"
      bordered={true}
      columns={columns}
      dataSource={nowData}
      loading={nowData.length == 0}
      pagination={false}/>
    <br />
    <Table
      align="center"
      size="small"
      style={{cellFontSizeSM:5}}
      bordered={true}
      columns={daysColumns}
      dataSource={daysData}
      loading={daysData.length == 0}
      pagination={false}/>
    <br />
    <Table
      align="center"
      size="small"
      bordered={true}
      columns={monthsColumns}
      dataSource={monthsData}
      loading={monthsData.length == 0}
      pagination={false}/>
      <br />
    </div>
    </ConfigProvider>
  );
};

export default TransactionAmountPage;
