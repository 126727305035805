import apiManager from "./api";

export function postLogin(loginId, password) {
    return apiManager.post(
        '/api/v3/admins/auth/sign_in', {
            login_id: loginId,
            password: password
        }
    )
}

export function getTransactionAmount(paySuccessAtGte, paySuccessAtLte) {
    return apiManager.get(
        '/api/v3/admins/orders/transaction-amount', {
            params: {
                pay_success_at: {gte : paySuccessAtGte, lte: paySuccessAtLte }
            }
        }
    )
    // https://api.lookpin.co.kr/api/v3/admins/orders/transaction-amount
}