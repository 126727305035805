import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { Analytics } from '@vercel/analytics/react';


dayjs.locale('ko');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <Analytics />
    <App />
    </>
);
